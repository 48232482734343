import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Header from "../components/header"
import Servicesection from "../components/servicesection"
import Aboutsection from "../components/aboutsection"
import Statsection from "../components/statsection"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />
    <Servicesection />
    <Aboutsection />
    <Statsection />
  </Layout>
)

export default IndexPage
