import React from "react";
import telecomTower from "../images/telecom_tower.jpg";
import linkManagement from "../images/link-management.jpg";
import solarPower from "../images/solar_power.jpg";

const Servicesection = () => (
  <section
    id="servicesection"
    className="px-10 lg:px-0 max-w-6xl mx-auto mt-20 mb-20"
  >
    <div className="text-center">
      <h1 className="capitalize text-3xl font-bold">What We Do</h1>
      <h2 className="pt-6">
        With its technically proficient team members CM teleservices offers wide
        range of telecom services including integrated design, engineering,
        procurement, construction and project management.
      </h2>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 col-gap-10 row-gap-10 py-10">
      <div className="flex flex-col border border-gray-400 rounded-lg shadow-xl overflow-hidden">
        <div>
          <figure>
            <img
              src={telecomTower}
              alt="Telecom Tower"
              className="object-cover object-top h-64 w-full overflow-hidden"
            />
          </figure>
        </div>
        <div className="p-6">
          <h5 className="font-bold pb-2 text-xl">Full Turnkey</h5>
          <p>
            CM Teleservices offers Full Turnkey site deployment services,
            starting from site planning, survey and design to final acceptance
            testing which includes infrastrucure development, tower erection,
            telecom equipment installation and comissioning.
          </p>
        </div>
      </div>
      <div className="flex flex-col border border-gray-400 rounded-lg shadow-xl overflow-hidden">
        <div>
          <figure>
            <img
              src={linkManagement}
              alt="Networking"
              className="object-cover object-top h-64 w-full overflow-hidden"
            />
          </figure>
        </div>
        <div className="p-6">
          <h5 className="font-bold pb-2 text-xl">Managed Services</h5>
          <p>
            CM Teleservices offers complete and customized package of solution
            to manage and enhance wireless operators performance, capacity and
            coverage of network and services, as per their requirement,
            providing them both control and flexiblity over their network.
          </p>
        </div>
      </div>
      <div className="flex flex-col border border-gray-400 rounded-lg shadow-xl overflow-hidden">
        <div>
          <figure>
            <img
              src={solarPower}
              alt="Solar farm"
              className="h-64 w-full object-cover object-center overflow-hidden"
            />
          </figure>
        </div>
        <div className="p-6">
          <h5 className="font-bold pb-2 text-xl">Electrical Works</h5>
          <p>
            CM Teleservices has experience, resource and technical skill
            required for design and contracting works in electrical and
            renewable engineering field.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Servicesection;
