import React from "react"



const Header = ({ siteTitle }) => (
  <section>
    <div className="h-screen w-screen is-hero">
      <div className="flex items-center h-full px-12">
        <div className="content">
          <h1 className="uppercase font-bold text-3xl text-white tracking-widest">
            Trust your Network with us
          </h1>
          <p className="text-white text-lg tracking-wide py-5">
            We are ready to take challenge <br />
            to help you expand your network.
          </p>
          <div className="pt-5">
            <a
              className="rounded-md border-2 px-4 py-3 shadow text-white font-bold tracking-wide hover:bg-white hover:text-blue-800"
              href="#servicesection"
            >
              Explore More
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Header
