import React from "react"
import dreamBig from "../images/dream_big.jpeg"
import Link from "gatsby-link"

const Aboutsection = () => (
  <section className="px-10 lg:px-0 max-w-6xl mx-auto my-20">
    <div className="">
      <div className="flex row-gap-10 items-center -mx-10">
        <div className="w-full md:w-1/2 px-10">
          <p className="font-bold text-3xl capitalize">About Us</p>
          <div className="md:hidden py-5">
            <img src={dreamBig} alt="dream big" />
          </div>
          <p className="py-6 mb-5">
            We are resourceful, we are skilled and we are disciplined. We
            believe in work with perfection, work with dignity and work with
            compassion. We have what it takes to make your business a success.
          </p>
          <Link
            to="about"
            className="rounded px-5 py-3 bg-blue-800 text-blue-100 tracking-wider hover:bg-blue-700"
          >
            Learn More
          </Link>
        </div>
        <div className="md:w-1/2 hidden md:flex px-10">
          <img src={dreamBig} alt="dream big" />
        </div>
      </div>
    </div>
  </section>
)

export default Aboutsection
