import React from "react"

const Statsection = () => (
  <section className="statBg  bg-cover bg-center text-center uppercase text-blue-100 mt-20">
    <div className="px-10 lg:px-0 max-w-6xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 row-gap-10 md:h-64 py-10 items-center">
        <div className="font-bold">
          <p className="text-xl tracking-wide">BTS Comissioned</p>
          <p className="pt-3">1234</p>
        </div>
        <div className="font-bold">
          <p className="text-xl tracking-wide">Civil Works Completed</p>
          <p className="pt-3">723</p>
        </div>
        <div className="font-bold">
          <p className="text-xl tracking-wide">Links Connected</p>
          <p className="pt-3">2179</p>
        </div>
      </div>
    </div>
  </section>
)

export default Statsection
